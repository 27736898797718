<template>
    <b-sidebar id="sidebar-right" right shadow bg-variant="white" width="80%" backdrop>
        <template #default="{ hide }">
            <b-card title="Email Template">
                <b-form>
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Screen Name">
                                <v-select placeholder="Please Select Screen Name" @input="selectScreenName"
                                    v-model=emailConfig.screenNameSelected :options="screenNameOptions" label="screenName">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Template Name">
                                <b-form-input v-model=emailConfig.templateName placeholder="Please Enter Template Name">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="CC Email">
                                <b-form-input v-model=emailConfig.ccEmail placeholder="Please Enter CC Email"></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Subject">
                                <b-form-input v-model=emailConfig.subject placeholder="Please Enter Subject"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Dynamic Variable">
                                <v-select placeholder="Please Select Dynamic Variable" @input="selectDynamicVariable"
                                    v-model="dynamicVariableSelected" :options="dynamicVariableOptions"
                                    label="dynamicVariableName"></v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Message">
                                <quill-editor v-model=emailConfig.emailContent :options="snowOption" />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group>
                                <b-form-checkbox v-model=emailConfig.isActive >IsActive</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="12" class="text-center">
                            <b-button variant="primary" class="mt-2 mr-2" v-on:click="addUpdateEmailTemplate"
                                @click="hide">{{ isEdit ?
        'Update' : 'Add'
                                }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </template>
    </b-sidebar> 
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BSidebar, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { integer } from 'vee-validate/dist/rules'
import util from '@/store/utils'

export default {
    components: {
        BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckbox,
        vSelect, quillEditor, BSidebar,util
    },
    data() {
        return {
            screenNameSelected: '',
            screenNameOptions: util.screenNameOptions,
            templateName: '',
            dynamicVariableSelected: '',
            dynamicVariableOptions: util.dynamicVariableOptions,
            ccEmail: '',
            subject: '',
            emailContent: '',
            isActive: false,
        }
    },
    methods: {
        selectScreenName() {
            this.emailConfig.templateName = this.emailConfig.screenNameSelected.screenName
        },

        selectDynamicVariable() {
            this.emailConfig.emailContent += '# ' + this.dynamicVariableSelected.dynamicVariableName + ' #'
        },
        addUpdateEmailTemplate() {
            let self = this;
            if (!(self.emailConfig.screenNameSelected.id == 0) && !(self.emailConfig.templateName == '') && !(self.emailConfig.ccEmail == '') && !(self.emailConfig.subject == '') && !(self.emailConfig.emailContent == '')) {
                if (!this.isEdit) {
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "screenName": this.emailConfig.screenNameSelected.id,
                            "templateName": this.emailConfig.templateName,
                            "ccEmail": this.emailConfig.ccEmail,
                            "subject": this.emailConfig.subject,
                            "emailContent": this.emailConfig.emailContent,
                            "active": this.emailConfig.isActive
                        }
                    );
                    axios(api.getApi('Post', '/masters/emailTemplates', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Email Template Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                           
                            self.getEmailTemplate();
                            
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {


                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "screenName": this.emailConfig.screenNameSelected.id,
                            "templateName": this.emailConfig.templateName,
                            "ccEmail": this.emailConfig.ccEmail,
                            "subject": this.emailConfig.subject,
                            "emailContent": this.emailConfig.emailContent,
                            "active": this.emailConfig.isActive
                        }
                    );
                    axios(api.getApi('put', '/masters/emailTemplates/' + this.emailConfig.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Email Template Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getEmailTemplate();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please fill all the mandatory fields.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
    },
    props: {
        isEdit: Boolean,
        getEmailTemplate: Function,
        emailConfig: Array
    },
    created() {
        //call method for update by id
       
    },
    mounted() {
        
    },
    computed: {
       
    },
}
</script>